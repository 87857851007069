<template>
  <div class="mt-0 pt-0">
    <v-row v-if="loading" justify="center" class="mt-15">
      <v-progress-circular class="ma-5" :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </v-row>

    <v-row v-else-if="rawCaptures.length <= 0 && !loading" justify="center" class="mt-15">
      <p class="mt-10 text-center">
        It looks like you have no browsing history.<br />Once you view other users captures, <a href=""
          @click="this.$router.go()">refresh</a> the page.
      </p>
    </v-row>

    <!-- single delete - separated if the user has selected items but wants to delete a single non-selected item -->
    <div v-else-if="!loading && getCaptures">
      <v-dialog v-model="deleteDialog" max-width="300">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Delete from history?</v-toolbar>
            <v-card-text>
              <div class="pt-5 pb-0 mb-0">
                Deleted history cannot be restored.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
              <v-btn color="error" text @click="(deleteDialog = false), deleteCapture(captureToDelete)">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- multi delete -->
      <v-dialog v-model="deleteSelectedDialog" max-width="300">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Delete from history?</v-toolbar>
            <v-card-text>
              <div class="pt-5 pb-0 mb-0">
                Deleted history cannot be restored.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="deleteSelectedDialog = false">Cancel</v-btn>
              <v-btn color="error" text @click="(deleteSelectedDialog = false), deleteSelected()">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-row>
        <v-col cols="12" class="mt-0 pt-0">
          <v-card-title class="pa-0 ma-1 pb-0 mb-0">
            <div class="monthTitle">
              Browsing History
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" dark size="24px" class="mb-1"
                    @click="selectAll()">mdi-select-all</v-icon>
                </template>
                <span>Select all</span>
              </v-tooltip>
            </div>

          </v-card-title>
        </v-col>
        <v-col v-if="!isMobile() && $store.state.view == 0" :class="{
          cols1: $vuetify.breakpoint.xs,
          cols2: $vuetify.breakpoint.sm,
          cols4: $vuetify.breakpoint.md,
          cols7: $vuetify.breakpoint.lg,
          cols10: $vuetify.breakpoint.xl,
        }" class="ma-0 pa-2" v-for="capture in getCaptures" :key="capture._id">
          <v-hover v-slot="{ hover }">
            <v-card :href="getCaptureURL(capture.file)" target="_blank" class="pr-1 pl-1 pt-1"
              :class="{ 'on-hover': hover }" :elevation="hover ? 10 : 2" :ripple="false" max-height="140" :style="capture.selected
                  ? 'border: 1px solid #1976D2;'
                  : 'border: 1px solid transparent;'
                ">
              <!-- if the capture is an image -->
              <div>
                <v-img class="pa-0" height="100" contain :src="getCaptureSrc(capture)">
                  <v-expand-transition>
                    <div v-if="hover || capture.selected">
                      <div class="d-flex transparents" style="height: 100px">
                        <v-checkbox @click.stop.prevent @click="selectCapture(capture)" color="blue" dark
                          class="pl-1 pt-0 ma-0" v-model="capture.selected"></v-checkbox>
                        <v-spacer></v-spacer>
                        <div>
                          <v-tooltip top color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" v-if="!capture.selected" @click.stop.prevent
                                @click="copyURL(capture)" class="pa-0 ma-0" color="white" size="20"
                                dark>mdi-content-copy</v-icon>
                            </template>
                            <span>Copy URL</span>
                          </v-tooltip>
                          <v-tooltip top color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" v-if="!capture.selected" @click.stop.prevent
                                @click="deleteCaptureButton(capture)" class="pa-0 ma-0" color="white" size="20"
                                dark>mdi-trash-can-outline</v-icon>
                            </template>
                            <span>Remove from History</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                  </v-expand-transition>
                </v-img>
              </div>
              <v-card-text class="captureTitle pt-0 pb-2 pl-1 pr-1 text-center">

              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
        <v-list-item v-else-if="!isMobile() && $store.state.view == 1">
          <v-card :href="getCaptureURL(capture.file)" target="_blank" class="pr-1 pl-1 pt-1 d-flex mb-1"
            :class="{ 'on-hover': hover }" :elevation="0" :ripple="false" style="width: 100%; min-width: 100%;"
            :style="capture.selected ? 'border: 1px solid #1976D2;' : 'border: 1px solid transparent;'">

            <div class="text-left d-flex" style="width: 80%; align-content: center; align-items: center;">
              <v-checkbox @click.stop.prevent @click="selectCapture(capture)" color="blue" class="ma-0 pa-0 mt-5"
                v-model="capture.selected" :checked="capture.selected"></v-checkbox>

              <v-img class="pa-0 ma-0" height="60" max-width="60" contain :src="getCaptureSrc(capture)"></v-img>
              <p class="ma-0 ml-2 pa-0"><small></small></p>
            </div>

            <div class="text-right d-flex" style="justify-content: right; justify-items: right; width: 20%;">
              <v-icon v-if="!capture.selected" @click.stop.prevent @click="copyURL(capture)" class="pa-0 ma-0 mr-1"
                color="black" size="20" dark>mdi-content-copy</v-icon>
              <v-icon v-if="!capture.selected" @click.stop.prevent @click="deleteCaptureButton(capture)" class="pa-0 ma-0"
                color="black" size="20" dark>mdi-trash-can-outline</v-icon>

            </div>
          </v-card>
        </v-list-item>
        <v-list-item v-else-if="isMobile()">
          <v-card :href="getCaptureURL(capture.file)" target="_blank" class="pr-1 pl-1 pt-1 d-flex mb-1"
            :class="{ 'on-hover': hover }" :elevation="0" :ripple="false" style="width: 100%; min-width: 100%;"
            :style="capture.selected ? 'border: 1px solid #1976D2;' : 'border: 1px solid transparent;'">

            <div class="text-left d-flex" style="width: 80%; align-content: center; align-items: center;">
              <v-checkbox @click.stop.prevent @click="selectCapture(capture)" color="blue" class="ma-0 pa-0 mt-5"
                v-model="capture.selected" :checked="capture.selected"></v-checkbox>

              <v-img class="pa-0 ma-0" height="60" max-width="60" contain :src="getCaptureSrc(capture)"></v-img>
              <p class="ma-0 ml-2 pa-0"><small></small></p>
            </div>

            <div class="text-right d-flex" style="justify-content: right; justify-items: right; width: 20%;">
              <v-icon v-if="!capture.selected" @click.stop.prevent @click="copyURL(capture)" class="pa-0 ma-0 mr-1"
                color="black" size="20" dark>mdi-content-copy</v-icon>
              <v-icon v-if="!capture.selected" @click.stop.prevent @click="deleteCaptureButton(capture)" class="pa-0 ma-0"
                color="black" size="20" dark>mdi-trash-can-outline</v-icon>

            </div>
          </v-card>
        </v-list-item>
      </v-row>


    </div>

    <v-navigation-drawer v-if="getSelectedLength > 0 && !isMobile()" app floating permanent width="250"
      class="app-navigation-menu">
      <!-- Navigation Header -->
      <div class="
          background
          vertical-nav-header
          d-flex
          items-center
          ps-6
          pt-2
          pb-2
        ">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img src="../../../public/white-logo.png" width="30px" alt="logo" contain eager class="app-logo mr-1"></v-img>
          <v-slide-x-transition>
            <h2 class="app-title">Onyshare</h2>
          </v-slide-x-transition>
        </router-link>
      </div>

      <!-- Navigation Items -->
      <v-list expand class="vertical-nav-menu-items mt-4">
        <v-list-item>
          <div class="text-left">
            <v-btn @click="clearSelected()" depressed elevation="0" width="210" class="pa-0 ma-0">
              <v-icon class="pr-1 pl-0">mdi-close</v-icon>
              Clear
            </v-btn>
          </div>
        </v-list-item>
        <v-list-item>
          <v-btn @click="copyURLSelected()" depressed elevation="0" width="210" class="pa-0 ma-0">
            <v-icon class="pr-1 pl-0">mdi-content-copy</v-icon>
            {{ getSelectedLength > 1 ? "Copy URLs" : "Copy URL" }}
          </v-btn>
        </v-list-item>

        <v-list-item>
          <v-btn @click="deleteSelectedButton()" depressed elevation="0" width="210" class="pa-0 ma-0">
            <v-icon class="pr-1 pl-0">mdi-trash-can-outline</v-icon>
            Remove
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <VerticalNavMenu v-if="getSelectedLength <= 0 && !isMobile()"></VerticalNavMenu>

    <v-footer app fixed grow color="primary" height="110" style="color: white" class="flex-column" v-if="isMobile()">
      <v-row class="justify-center ma-0 pa-0" style="color: white" v-if="getSelectedLength > 0">
        <v-icon @click.stop.prevent @click="clearSelected()" class="pa-0 ma-3 mt-0" color="white" size="34"
          dark>mdi-close</v-icon>
        <v-icon @click.stop.prevent @click="copyURLSelected()" class="pa-0 ma-3 mt-0" color="white" size="34"
          dark>mdi-content-copy</v-icon>
        <v-icon @click.stop.prevent @click="deleteSelectedButton()" class="pa-0 ma-3 mt-0" color="white" size="34"
          dark>mdi-trash-can-outline</v-icon>
      </v-row>

      <v-row class="justify-center ma-0 pa-0" style="color: white" v-else-if="getSelectedLength <= 0">
        <v-icon @click.stop.prevent @click="$router.push('/dashboard/account')" class="pa-0 ma-2 mt-0" color="white"
          size="34" dark>mdi-account-cog-outline</v-icon>
        <v-icon @click.stop.prevent @click="$router.push('/dashboard/captures')" class="pa-0 ma-2 mt-0" color="white"
          size="34" dark>mdi-file-image-outline</v-icon>
        <v-icon @click.stop.prevent @click="" class="pa-0 ma-2 mt-0" color="white" size="34"
          dark>mdi-file-multiple</v-icon>
        <v-icon @click.stop.prevent @click="$router.push('/dashboard/history')" class="pa-0 ma-2 mt-0" color="white"
          size="34" dark>mdi-history</v-icon>

        <v-row class="justify-center ma-0 mt-2 pa-0" style="width: 100%">
          <v-progress-linear class="ma-0 pa-0" color="green" height="10" :value="sizeValue"></v-progress-linear>
          <small class="ma-0 pa-0">{{ footerData }} </small></v-row>
        <v-row class="justify-center ma-0 mt-0 pa-0"><small v-if="!this.$store.state.user.premium">Increase your
            storage by going Pro</small><small v-if="this.$store.state.user.premium"></small></v-row>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import config from "../../config";
import NavMenuItem from "./NavMenuItem.vue";
import "vue-toasted/dist/vue-toasted.min.css";
import VerticalNavMenu from "./VerticalNavMenu.vue";

export default {
  name: "Images",
  components: {
    VerticalNavMenu,
    NavMenuItem,
  },
  data: function () {
    return {
      captureTitle: "",
      captureDesc: "",
      capturePrivate: true,
      deleteDialog: false,
      deleteSelectedDialog: false,
      editMetaData: false,
      hover: false,
      select: null,
      codebinSelect: null,
      loading: true,
      captures: {},
      rawCaptures: {},
      searchCaptures: {},
      captureToDelete: {},
      capturesDeleting: [],
      selectedCaptures: [],
      captureMetaData: [
        {
          name: "Private metadata",
          icon: "mdi-eye-off-outline",
        },
        {
          name: "Public metadata",
          icon: "mdi-eye-outline",
        },
      ],
      codebinLang: [
        {
          name: "aspnet",
        },
        {
          name: "csharp",
        },
        {
          name: "clike",
        },
        {
          name: "c",
        },
        {
          name: "java",
        },
        {
          name: "js",
        },
        {
          name: "txt",
        },
        {
          name: "html",
        },
        {
          name: "css",
        },
        {
          name: "php",
        },
        {
          name: "r",
        },
        {
          name: "py",
        },
        {
          name: "fsharp",
        },
        {
          name: "rust",
        },
      ],
    };
  },
  computed: {
    footerData() {
      var bytes = parseInt(this.$store.state.user.size);

      if (bytes == 0) {
        if (this.$store.state.user.premium) {
          return "0% of storage used";
        } else {
          return "0% of storage used";
        }
      }
      if (this.$store.state.user.premium) {
        return `${(bytes / 107374182400 * 100).toFixed(2)}% of storage used`;
      } else {
        return `${(bytes / 104857600 * 100).toFixed(2)}% of storage used`;
      }
    },
    sizeValue() {
      if (this.$store.state.user.premium) {
        return parseInt((this.$store.state.user.size / 107374182400) * 100);
      } else {
        return parseInt((this.$store.state.user.size / 104857600) * 100);
      }
    },
    getSelectedLength() {
      return this.selectedCaptures.length;
    },
    getCaptures() {
      return this.rawCaptures;
    },
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
        return false;
      }

      return true;
    },
    selectAll() {
      for (let i = 0; i < this.rawCaptures.length; i++) {
        if (!this.selectedCaptures.includes(this.rawCaptures[i])) {
          this.selectCapture(this.rawCaptures[i]);
          this.rawCaptures[i].selected = true;
        }
      }
    },
    getCaptureSrc(capture) {
      if (capture.type == "mp4") {
        capture.url = capture.url.replace(".mp4", ".gif");
      }

      if (capture.type == "txt") {
        return "https://c.onyshare.com/file/onyshare-eu/default-code.png"
      }

      return capture.url;
    },
    GetCodeBinLang(capture) {
      return capture.lang;
    },
    copyURLSelected() {
      var copyText = "";
      for (let i = 0; i < this.selectedCaptures.length; i++) {
        if (i == 0) {
          copyText += this.getCaptureURL(this.selectedCaptures[i].file);
        } else {
          copyText += "\r\n" + this.getCaptureURL(this.selectedCaptures[i].file);
        }
      }

      navigator.clipboard.write(copyText);

      this.$toasted.show(
        this.selectedCaptures.length > 1
          ? "Copied URLs to Clipboard"
          : "Copied URL to Clipboard",
        {
          theme: "toasted-primary",
          type: "info",
          position: "top-center",
          duration: 2000,
          iconPack: "mdi",
          icon: "content-copy",
        }
      );
    },
    copyURL(capture) {
      navigator.clipboard.writeText(this.getCaptureURL(capture.file));

      this.$toasted.show("Copied URL to Clipboard", {
        theme: "toasted-primary",
        type: "info",
        position: "top-center",
        duration: 2000,
        iconPack: "mdi",
        icon: "content-copy",
      });
    },
    clearSelected() {
      for (let i = 0; i < this.selectedCaptures.length; i++) {
        this.selectedCaptures[i].selected = false;
      }
      this.selectedCaptures = [];
    },
    selectCapture(capture) {
      for (let i = 0; i < this.selectedCaptures.length; i++) {
        if (this.selectedCaptures[i].file == capture.file) {
          this.selectedCaptures[i].selected = false;
          this.selectedCaptures.splice(i, 1);
          return;
        }
      }
      capture.select = true;
      this.selectedCaptures.push(capture);
    },
    deleteSelectedButton() {
      this.deleteSelectedDialog = true;
    },
    deleteSelected() {
      var data = [];
      for (let i = 0; i < this.selectedCaptures.length; i++) {
        data.push(this.selectedCaptures[i].file);
      }

      this.$fetch(`${config.URL}/users/historyds`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;

            this.$toasted.show("Failed to delete", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "trash-can-outline",
            });
            this.capturesDeleting = [];
          } else {
            res.json().then((data) => {
              this.rawCaptures = data;
              this.loading = false;
              this.clearSelected();
              this.$toasted.show("Successfully deleted", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "trash-can-outline",
              });
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to delete", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "trash-can-outline",
          });
        });
      return;
    },
    deleteCaptureButton(capture) {
      this.captureToDelete = capture;
      this.deleteDialog = true;
    },
    deleteCapture(capture) {
      this.captureToDelete = {};

      if (this.capturesDeleting.includes(capture.file)) {
        return;
      }

      this.capturesDeleting.push(capture.file);

      this.$fetch(`${config.URL}/users/historyd/` + capture.file)
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;

            this.$toasted.show("Failed to delete", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "trash-can-outline",
            });
            this.capturesDeleting = [];
          } else {
            res.json().then((data) => {
              this.rawCaptures = data;
              this.loading = false;
              this.clearSelected();
              this.$toasted.show("Successfully deleted", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "trash-can-outline",
              });
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to delete", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "trash-can-outline",
          });
        });
    },
    getCaptureURL(captureID) {
      //window.open(config.URL + "/c/" + captureID, '_blank');
      return config.URL + "/c/" + captureID;
      //return "http://localhost:8080" + "/c/" + captureID;
    },
    getSmallerText(text) {
      if (text.length > 17) {
        return text.substring(0, 17) + "...";
      }
      return text;
    },
    getDateString(month) {
      switch (month) {
        case "1":
          return "January";
        case "2":
          return "February";
        case "3":
          return "March";
        case "4":
          return "April";
        case "5":
          return "May";
        case "6":
          return "June";
        case "7":
          return "July";
        case "8":
          return "August";
        case "9":
          return "September";
        case "10":
          return "October";
        case "11":
          return "November";
        case "12":
          return "December";
        default:
          break;
      }
    },
  },
  created: function () {
    this.$fetch(`${config.URL}/users/history`)
      .then((res) => {
        if (res.status != 200) {
          this.loading = false;
          this.$toasted.show("Failed to load images", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "image-remove",
          });
        } else {
          res.json().then((data) => {
            this.rawCaptures = data;
            this.loading = false;
          });
        }
      })
      .catch((err) => console.log("ERROR", err));
  },
};
</script>

<style>
.monthTitle {
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  background-color: #1976d2;
  width: 100%;
  height: 50px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  line-height: 50px;
}

.video {
  position: absolute;
  z-index: 0;
}

.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: white;
}

.background {
  background-color: #1976d2;
}

.captureTitle {
  font-size: 0.74em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cols10 {
  width: 10%;
  max-width: 10%;
  flex-basis: 10%;
}

.cols7 {
  width: 14.285%;
  max-width: 14.285%;
  flex-basis: 14.285%;
}

.cols4 {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.cols3 {
  width: 33%;
  max-width: 33%;
  flex-basis: 33%;
}

.cols2 {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.cols1 {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.v-checkbox {
  color: white;
}

.transparents {
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-color: transparent !important;
}</style>
