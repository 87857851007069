<template>
  <div>
    <v-main>
      <v-container class="pt-0 ma-0">
        <v-app-bar app fixed flat dense center color="primary" height="46">
          <!-- Navigation Header -->
          <div class="background vertical-nav-header d-flex items-center ps-4 pt-2 pb-2" v-if="isMobile()">
            <router-link to="/" class="d-flex align-center text-decoration-none">
              <v-img src="../../public/white-logo.png" width="30px" alt="logo" contain eager
                class="app-logo mr-1"></v-img>
            </router-link>
          </div>
          <v-text-field class="flex-grow-0 pl-3 pr-3" prepend-inner-icon="mdi-magnify" placeholder="Search for captures"
            outlined dense hide-details clearable background-color="white" v-model="message"
            @input="search(message)"></v-text-field>
          <v-spacer></v-spacer>

          <v-icon v-if="!isMobile() && this.$store.state.view == 0" @click.stop.prevent @click="changeView()" class="pa-0 ma-2"
            color="white" size="30" dark>mdi-view-grid</v-icon>
          <v-icon v-else-if="!isMobile() && this.$store.state.view == 1" @click.stop.prevent @click="changeView()" class="pa-0 ma-2"
            color="white" size="30" dark>mdi-view-headline</v-icon>
          <user-menu></user-menu>
        </v-app-bar>
        <router-view class="mt-4"></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import UserMenu from "../components/dashboard/UserMenu.vue";

export default {
  name: "Dashboard",
  data: function () {
    return {
      message: "",
    };
  },
  components: {
    UserMenu,
  },
  mounted(){
    if(localStorage.getItem("Onyshare-View")){
      this.$store.state.view = localStorage.getItem("Onyshare-View");
    }
  },
  methods: {
    changeView(){
      if(this.$store.state.view == 0){
        this.$store.state.view += 1;
      }else{
        this.$store.state.view -= 1;
      }
      localStorage.setItem("Onyshare-View", this.$store.state.view);
    },
    isMobile() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
        return false;
      }

      return true;
    },
    search(input) {
      if (input == null) {
        this.$store.state.search = "";
      } else {
        if (this.$route.name != "captures") {
          this.$router.push("/dashboard/captures");
        }
        this.$store.state.search = input;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-toolbar ::v-deep {
  .v-toolbar__content {
    padding: 0;
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.v-text-field {
  width: 400px;
}
</style>
