<template>
  <div>
    <VerticalNavMenu v-if="!isMobile()"></VerticalNavMenu>

    <!-- Navigation Header -->
    <v-row class="" justify="center">
      <template>
        <v-card class="ma-2" width="270" height="340">
          <v-card-text>
            <v-img src="../../assets/images/undraw_forgot_password_re_hxwm.svg" height="160px"></v-img>
            <p class="pt-2 mb-2 text text--primary">Reset Password</p>
            <div class="text--primary">
              Request a password reset for your account.<br />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" @click.stop="resetpass = true">
              Reset Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>

      <v-dialog max-width="400" v-model="resetpass">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Reset your password?</v-toolbar>
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                A confirmation email will be sent to you containing a link on
                how to reset your password.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="resetPassword()"
                >Reset Password</v-btn
              >
              <v-btn text color="red" @click="resetpass = false"
                >No Thanks</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- confirmed reset password -->
      <v-dialog max-width="400" v-model="resetpassConfirm">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Success!</v-toolbar>
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                A confirmation email has been sent to you. Please follow the
                link inside to reset your password.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="resetpassConfirm = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- failed reset password -->
      <v-dialog max-width="400" v-model="resetpassFail">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Something went wrong!</v-toolbar>
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                There was an error processing your request to change your
                password. Please try again later.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="resetpassFail = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-card class="ma-2" width="270" height="340">
        <v-card-text>
          <v-img src="../../assets/images/undraw_subscriptions_re_k7jj.svg" height="160px"></v-img>
          <p class="pt-2 mb-2 text text--primary">Manage Subscriptions</p>
          <div class="text--primary">
            Manage your Onyshare subscriptions and more.<br />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary"> Manage Subscriptions </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="ma-2" width="270" height="340">
        <v-card-text>
          <v-img src="../../assets/images/undraw_secure_login_pdn4.svg" height="160px"></v-img>
          <p class="pt-2 mb-2 text text--primary">2-Factor Authentication</p>
          <div class="text--primary">
            Enable or disable 2-factor authentication on your account.<br />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="twofactor = true">
            2-Factor Authentication
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- 2fa -->
      <v-dialog max-width="400" v-model="twofactor">
        <template>
          <v-card v-if="!this.$store.state.user.twofactor">
            <v-toolbar color="primary" dark
              >Enable 2-Factor Authentication?</v-toolbar
            >
            <v-card-text>
              <v-form ref="form" v-model="isFormValid">
                <div class="pt-4 pb-0 text--primary">
                  Enabling 2-Factor Authentication gives an extra layer of
                  protection and security on your account.
                  <br /><br />
                  Please input your account password to confirm 2-factor
                  authentication.
                </div>
                <v-text-field
                  outlined
                  dense
                  full-width
                  single-line
                  class="mt-4"
                  name="password"
                  label="Account Password"
                  type="password"
                  v-model="password"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                color="primary"
                @click="enabletwofa()"
                :disabled="!isFormValid"
                >Enable 2-fa</v-btn
              >
              <v-btn text color="red" @click="twofactor = false"
                >No Thanks</v-btn
              >
            </v-card-actions>
          </v-card>
          <v-card v-if="this.$store.state.user.twofactor">
            <v-toolbar color="primary" dark
              >Disable 2-Factor Authentication?</v-toolbar
            >
            <v-card-text>
              <v-form ref="form" v-model="isFormValid">
                <div class="pt-4 pb-0 text--primary">
                  Disabling 2-Factor Authentication will lose an extra layer of
                  protection and security on your account.
                  <br /><br />
                  Please input your account password to confirm.
                </div>
                <v-text-field
                  outlined
                  dense
                  full-width
                  single-line
                  class="mt-4"
                  name="password"
                  label="Account Password"
                  type="password"
                  v-model="password"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                color="primary"
                @click="disabletwofa()"
                :disabled="!isFormValid"
                >Disable 2-fa</v-btn
              >
              <v-btn text color="red" @click="twofactor = false"
                >No Thanks</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- success enable 2fa -->
      <v-dialog max-width="400" v-model="enabletwofactor">
        <template>
          <v-card>
            <v-toolbar color="primary" dark
              >Two-factor authentication enabled</v-toolbar
            >
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                You have successfully enabled two-factor authentication on this
                account.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="enabletwofactor = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- success enable 2fa -->
      <v-dialog max-width="400" v-model="disabletwofactor">
        <template>
          <v-card>
            <v-toolbar color="primary" dark
              >Two-factor authentication disabled</v-toolbar
            >
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                You have successfully disabled two-factor authentication on this
                account.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="disabletwofactor = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- failed request -->
      <v-dialog max-width="400" v-model="failedrequest">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Something went wrong!</v-toolbar>
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                There was an error processing your request. Please try again
                later.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="failedrequest = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-card class="ma-2" width="270" height="340">
        <v-card-text>
          <v-img src="../../assets/images/undraw_throw_away_re_x60k.svg" height="160px"></v-img>
          <p class="pt-2 mb-2 text text--primary">Delete Account</p>
          <div class="0 text--primary">
            Permanently erase all data and close your account. <br />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click.stop="deleteaccount = true">
            Delete My Account
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- delete account-->
      <v-dialog max-width="400" v-model="deleteaccount">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Delete your account?</v-toolbar>
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                A confirmation email will be sent to you containing a link on
                how to delete your account.<br /><br />
                Deleting an account will erase all data and captures.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="deleteAccount()"
                >Delete Account</v-btn
              >
              <v-btn text color="red" @click="deleteaccount = false"
                >No Thanks</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- confirmed delete account -->
      <v-dialog max-width="400" v-model="deleteaccountConfirm">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Success!</v-toolbar>
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                A confirmation email has been sent to you. Please follow the
                link inside to delete your account.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="deleteaccountConfirm = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- failed reset password -->
      <v-dialog max-width="400" v-model="deleteaccountFail">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Something went wrong!</v-toolbar>
            <v-card-text>
              <div class="pt-4 pb-0 text--primary">
                There was an error processing your request to delete your
                account. Please try again later.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text color="primary" @click="deleteaccountFail = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-row>

    <v-footer app fixed grow color="primary" height="110" style="color: white" class="flex-column" v-if="isMobile()">
      <v-row class="justify-center ma-0 pa-0" style="color: white">
        <v-icon @click="$router.push('/dashboard/account')" class="pa-0 ma-2 mt-0" color="white" size="34"
          dark>mdi-account-cog-outline</v-icon>
        <v-icon @click="$router.push('/dashboard/captures')" class="pa-0 ma-2 mt-0" color="white" size="34"
          dark>mdi-file-image-outline</v-icon>
        <v-icon @click.stop.prevent @click="" class="pa-0 ma-2 mt-0" color="white" size="34" dark>mdi-file-multiple</v-icon>
        <v-icon @click.stop.prevent @click="$router.push('/dashboard/history')" class="pa-0 ma-2 mt-0" color="white" size="34" dark>mdi-history</v-icon>

        <v-row class="justify-center ma-0 mt-2 pa-0" style="width: 100%">
          <v-progress-linear color="green" height="10" :value="sizeValue"></v-progress-linear>
          <small>{{ footerData }} </small></v-row>
        <v-row class="justify-center ma-0 mt-0 pa-0 mb-3"><small v-if="!this.$store.state.user.premium">Increase your
            storage by going Pro</small><small v-if="this.$store.state.user.premium"></small></v-row>
      </v-row>
    </v-footer>
  </div>
</template>



<script>
import config from "../../config";
import VerticalNavMenu from "./VerticalNavMenu.vue";

export default {
  name: "Account",
  components: {
    VerticalNavMenu,
  },
  data: function () {
    return {
      rules: {
        required: (value) => !!value || "Required.",
      },
      password: "",
      isFormValid: false,
      resetpass: false,
      resetpassConfirm: false,
      resetpassFail: false,
      deleteaccount: false,
      deleteaccountConfirm: false,
      deleteaccountFail: false,
      twofactor: false,
      failedrequest: false,
      enabletwofactor: false,
      disabletwofactor: false,
    };
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
        return false;
      }

      return true;
    },
    enabletwofa() {
      this.twofactor = false;
      this.$fetch(`${config.URL}/users/etfa`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.$store.state.user.email,
          pass: this.password,
        }),
      })
        .then((res) => {
          this.password = "";
          if (res.status === 200) {
            this.$store.state.user.twofactor = true;
            this.twofactor = false;
            this.failedrequest = false;
            this.enabletwofactor = true;
          } else {
            this.twofactor = false;
            this.failedrequest = true;
          }
        })
        .catch((err) => {
          this.password = "";
          this.twofactor = false;
          this.failedrequest = true;
        });
    },
    disabletwofa() {
      this.twofactor = false;
      this.$fetch(`${config.URL}/users/dtfa`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: this.$store.state.user.email,
          pass: this.password,
        }),
      })
        .then((res) => {
          this.password = "";
          if (res.status === 200) {
            this.$store.state.user.twofactor = false;
            this.twofactor = false;
            this.failedrequest = false;
            this.disabletwofactor = true;
          } else {
            this.twofactor = false;
            this.failedrequest = true;
          }
        })
        .catch((err) => {
          this.password = "";
          this.twofactor = false;
          this.failedrequest = true;
        });
    },
    resetPassword() {
      this.password = "";
      this.resetpass = false;
      this.resetpassConfirm = false;
      this.resetpassFail = false;
      this.deleteaccount = false;
      this.deleteaccountConfirm = false;
      this.deleteaccountFail = false;

      this.$fetch(`${config.URL}/users/rp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: this.$store.state.user.email }),
      })
        .then((res) => {
          if (res.status === 200) {
            this.resetpass = false;
            this.resetpassConfirm = true;
            this.resetpassFail = false;
            this.deleteaccount = false;
            this.deleteaccountConfirm = false;
            this.deleteaccountFail = false;
          } else {
            this.resetpass = false;
            this.resetpassConfirm = false;
            this.resetpassFail = true;
            this.deleteaccount = false;
            this.deleteaccountConfirm = false;
            this.deleteaccountFail = false;
          }
        })
        .catch((err) => {
          this.resetpass = false;
          this.resetpassConfirm = false;
          this.resetpassFail = true;
          this.deleteaccount = false;
          this.deleteaccountConfirm = false;
          this.deleteaccountFail = false;
        });
    },
    deleteAccount() {
      this.password = "";
      this.resetpass = false;
      this.resetpassConfirm = false;
      this.resetpassFail = false;
      this.deleteaccount = false;
      this.deleteaccountConfirm = false;
      this.deleteaccountFail = false;

      this.$fetch(`${config.URL}/users/deleteaccount`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: this.$store.state.user.email }),
      })
        .then((res) => {
          if (res.status === 200) {
            this.resetpass = false;
            this.resetpassConfirm = false;
            this.resetpassFail = false;
            this.deleteaccount = false;
            this.deleteaccountConfirm = true;
            this.deleteaccountFail = false;
          } else {
            this.resetpass = false;
            this.resetpassConfirm = false;
            this.resetpassFail = false;
            this.deleteaccount = false;
            this.deleteaccountConfirm = false;
            this.deleteaccountFail = true;
          }
        })
        .catch((err) => {
          this.password = "";
          this.resetpass = false;
          this.resetpassConfirm = false;
          this.resetpassFail = false;
          this.deleteaccount = false;
          this.deleteaccountConfirm = false;
          this.deleteaccountFail = true;
        });
    },
  },
  computed: {
    sizeValue() {
      if (this.$store.state.user.premium) {
        return parseInt((this.$store.state.user.size / 107374182400) * 100);
      } else {
        return parseInt((this.$store.state.user.size / 104857600) * 100);
      }
    },
    footerData() {
      var bytes = parseInt(this.$store.state.user.size);

      if (bytes == 0) {
        if (this.$store.state.user.premium) {
          return "0% of storage used";
        } else {
          return "0% of storage used";
        }
      }
      if (this.$store.state.user.premium) {
        return `${(bytes / 107374182400 * 100).toFixed(2)}% of storage used`;
      } else {
        return `${(bytes / 104857600 * 100).toFixed(2)}% of storage used`;
      }
    },
    IsLoggedIn() {
      return this.$store.state.connected;
    },
  },
};
</script>

<style>
.text {
  font-size: 18px;
  font-weight: 500;
}
</style>
