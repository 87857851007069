<template>
  <div>
    <v-row v-if="loading" justify="center" class="mt-15">
      <v-progress-circular class="ma-5" :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </v-row>

    <v-row v-else-if="rawCaptures.length <= 0 && !loading" justify="center" class="mt-15">
      <p class="mt-10 text-center">
        It looks like you have no captures to show.<br />If you have just taken
        a capture, <a href="" @click="this.$router.go()">refresh</a> the page or
        go to the <a href="https://onyshare.com/download">Download</a> section
        to start using Onyshare.
      </p>
    </v-row>

    <!-- single delete - separated if the user has selected items but wants to delete a single non-selected item -->
    <div v-else-if="!loading && getCaptures">
      <v-dialog v-model="deleteDialog" max-width="300">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Delete capture?</v-toolbar>
            <v-card-text>
              <div class="pt-5 pb-0 mb-0">
                Deleted captures cannot be restored.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
              <v-btn color="error" text @click="(deleteDialog = false), deleteCapture(captureToDelete)">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- multi delete -->
      <v-dialog v-model="deleteSelectedDialog" max-width="300">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Delete capture?</v-toolbar>
            <v-card-text>
              <div class="pt-5 pb-0 mb-0">
                Deleted captures cannot be restored.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="deleteSelectedDialog = false">Cancel</v-btn>
              <v-btn color="error" text @click="(deleteSelectedDialog = false), deleteSelected()">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <!-- edit metadata -->
      <v-dialog v-model="editMetaData" max-width="600">
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Edit Metadata</v-toolbar>
            <v-card-text>
              <div class="pt-5 pb-0 mb-0">
                <v-text-field placeholder="Title" style="width: 600px" outlined :value="captureTitle"
                  v-model="captureTitle">
                </v-text-field>
                <v-textarea class="ma-0" style="width: 600px" outlined height="200px"
                  placeholder="Description (markup and urls allowed)" :value="captureDesc" v-model="captureDesc">
                </v-textarea>
                <v-select v-if="selectedCaptures.length == 1" v-model="select" class="ma-0 pb-0" :items="captureMetaData"
                  outlined item-text="name" style="width: 600px" :append-icon="GetMetaIcon(select, selectedCaptures[0])"
                  :value="GetMetaLabel(selectedCaptures[0])">
                  <template v-slot:item="slotProps">
                    <v-icon class="mr-2">{{ slotProps.item.icon }}</v-icon>
                    {{ slotProps.item.name }}
                  </template>
                </v-select>
                <v-select v-if="selectedCaptures.length == 1 && selectedCaptures[0].type == 'txt'" v-model="codebinSelect"
                  class="ma-0 pb-0" :items="codebinLang" outlined item-text="name" style="width: 600px"
                  :value="GetCodeBinLang(selectedCaptures[0])">
                  <template v-slot:item="codes">
                    {{ codes.item.name }}
                  </template>
                </v-select>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="editMetaData = false">Cancel</v-btn>
              <v-btn color="green" text @click="(editMetaData = false), SendMeta()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-row v-for="(month, key, index) in getCaptures" :key="index">
        <v-col cols="12" class="mt-0 pt-0">
          <v-card-title class="pa-0 ma-1 pb-0 mb-0">
            <div class="monthTitle">
              {{ `${getDateString(key.split("_")[0])} ${key.split("_")[1]}` }}
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" dark size="24px" class="mb-1"
                    @click="selectCapturesByMonth(key)">mdi-select-all</v-icon>
                </template>
                <span>Select all</span>
              </v-tooltip>
            </div>

          </v-card-title>
        </v-col>
        <v-col v-if="!isMobile() && $store.state.view == 0" :class="{
          cols1: $vuetify.breakpoint.xs,
          cols2: $vuetify.breakpoint.sm,
          cols4: $vuetify.breakpoint.md,
          cols7: $vuetify.breakpoint.lg,
          cols10: $vuetify.breakpoint.xl,
        }" class="ma-0 pa-2" v-for="capture in month" :key="capture.file">
          <v-hover v-slot="{ hover }">
            <v-card :href="getCaptureURL(capture.file)" target="_blank" class="pr-1 pl-1 pt-1"
              :class="{ 'on-hover': hover }" :elevation="hover ? 10 : 2" :ripple="false" max-height="140" :style="
                capture.selected
                  ? 'border: 1px solid #1976D2;'
                  : 'border: 1px solid transparent;'
              ">
              <!-- if the capture is an image -->
              <div>
                <v-img class="pa-0" height="100" contain :src="getCaptureSrc(capture)">
                  <v-expand-transition>
                    <div v-if="hover || capture.selected">
                      <div class="d-flex transparents" style="height: 100px">
                        <v-checkbox @click.stop.prevent @click="selectCapture(capture)" color="blue" dark
                          class="pl-1 pt-0 ma-0" v-model="capture.selected" :checked="capture.selected"></v-checkbox>
                        <v-spacer></v-spacer>
                        <div>
                          <v-tooltip top color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" v-if="!capture.selected" @click.stop.prevent
                                @click="copyURL(capture)" class="pa-0 ma-0" color="white" size="20"
                                dark>mdi-content-copy</v-icon>
                            </template>
                            <span>Copy URL</span>
                          </v-tooltip>
                          <v-tooltip top color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" v-if="!capture.selected" @click.stop.prevent
                                @click="deleteCaptureButton(capture)" class="pa-0 ma-0" color="white" size="20"
                                dark>mdi-trash-can-outline</v-icon>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>

                          <v-tooltip top color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" v-if="!capture.selected && capture.private"
                                class="pa-0 ma-0" color="white" size="20" dark>mdi-eye-off-outline</v-icon>
                            </template>
                            <span>Private metadata</span>
                          </v-tooltip>
                          <v-tooltip top color="primary">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" v-if="!capture.selected && !capture.private"
                                class="pa-0 ma-0" color="white" size="20" dark>mdi-eye-outline</v-icon>
                            </template>
                            <span>Public metadata</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                  </v-expand-transition>
                </v-img>
              </div>
              <v-card-text class="captureTitle pt-0 pb-0 pl-1 pr-1 text-center">
                {{ getSmallerText(capture.name) }}
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
        <v-list-item v-else-if="!isMobile() && $store.state.view == 1">
          <v-card :href="getCaptureURL(capture.file)" target="_blank" class="pr-1 pl-1 pt-1 d-flex mb-1"
            :class="{ 'on-hover': hover }" :elevation="0" :ripple="false" style="width: 100%; min-width: 100%;"
            :style="capture.selected ? 'border: 1px solid #1976D2;' : 'border: 1px solid transparent;'">

            <div class="text-left d-flex" style="width: 80%; align-content: center; align-items: center;">
              <v-checkbox @click.stop.prevent @click="selectCapture(capture)" color="blue" class="ma-0 pa-0 mt-5"
                v-model="capture.selected" :checked="capture.selected"></v-checkbox>

              <v-img class="pa-0 ma-0" height="60" max-width="60" contain :src="getCaptureSrc(capture)"></v-img>
              <p class="ma-0 ml-2 pa-0"><small>{{ getListText(capture.name) }}</small></p>
            </div>

            <div class="text-right d-flex" style="justify-content: right; justify-items: right; width: 20%;">
              <v-icon v-if="!capture.selected" @click.stop.prevent @click="copyURL(capture)" class="pa-0 ma-0 mr-1"
                color="black" size="20" dark>mdi-content-copy</v-icon>
              <v-icon v-if="!capture.selected" @click.stop.prevent @click="deleteCaptureButton(capture)" class="pa-0 ma-0"
                color="black" size="20" dark>mdi-trash-can-outline</v-icon>
            </div>
          </v-card>

        </v-list-item>
        <v-list-item v-else-if="isMobile()">
          <v-card :href="getCaptureURL(capture.file)" target="_blank" class="pr-1 pl-1 pt-1 d-flex mb-1"
            :class="{ 'on-hover': hover }" :elevation="0" :ripple="false" style="width: 100%; min-width: 100%;"
            :style="capture.selected ? 'border: 1px solid #1976D2;' : 'border: 1px solid transparent;'">

            <div class="text-left d-flex" style="width: 80%; align-content: center; align-items: center;">
              <v-checkbox @click.stop.prevent @click="selectCapture(capture)" color="blue" class="ma-0 pa-0 mt-5"
                v-model="capture.selected" :checked="capture.selected"></v-checkbox>

              <v-img class="pa-0 ma-0" height="60" max-width="60" contain :src="getCaptureSrc(capture)"></v-img>
              <p class="ma-0 ml-2 pa-0"><small>{{ getSmallerText(capture.name) }}</small></p>
            </div>

            <div class="text-right d-flex" style="justify-content: right; justify-items: right; width: 20%;">
              <v-icon v-if="!capture.selected" @click.stop.prevent @click="copyURL(capture)" class="pa-0 ma-0 mr-1"
                color="black" size="20" dark>mdi-content-copy</v-icon>
              <v-icon v-if="!capture.selected" @click.stop.prevent @click="deleteCaptureButton(capture)" class="pa-0 ma-0"
                color="black" size="20" dark>mdi-trash-can-outline</v-icon>
            </div>
          </v-card>

        </v-list-item>
      </v-row>
    </div>

    <v-navigation-drawer v-if="getSelectedLength > 0 && !isMobile()" app floating permanent width="250"
      class="app-navigation-menu">
      <!-- Navigation Header -->
      <div class="
            background
            vertical-nav-header
            d-flex
            items-center
            ps-6
            pt-2
            pb-2
          ">
        <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img src="../../../public/white-logo.png" width="30px" alt="logo" contain eager class="app-logo mr-1"></v-img>
          <v-slide-x-transition>
            <h2 class="app-title">Onyshare</h2>
          </v-slide-x-transition>
        </router-link>
      </div>

      <!-- Navigation Items -->
      <v-list expand class="vertical-nav-menu-items mt-4">
        <v-list-item>
          <div class="text-left">
            <v-btn @click="clearSelected()" depressed elevation="0" width="210" class="pa-0 ma-0">
              <v-icon class="pr-1 pl-0">mdi-close</v-icon>
              Clear
            </v-btn>
          </div>
        </v-list-item>
        <v-list-item v-if="getSelectedLength == 1">
          <v-btn @click="editMetaDataSelected(selectedCaptures[0])" depressed elevation="0" width="210" class="pa-0 ma-0">
            <v-icon class="pr-1 pl-0">mdi-pencil-outline</v-icon>
            Edit metadata
          </v-btn>
        </v-list-item>

        <v-list-item>
          <v-btn @click="copyURLSelected()" depressed elevation="0" width="210" class="pa-0 ma-0">
            <v-icon class="pr-1 pl-0">mdi-content-copy</v-icon>
            {{ getSelectedLength > 1 ? "Copy URLs" : "Copy URL" }}
          </v-btn>
        </v-list-item>

        <v-list-item>
          <v-btn @click="deleteSelectedButton()" depressed elevation="0" width="210" class="pa-0 ma-0">
            <v-icon class="pr-1 pl-0">mdi-trash-can-outline</v-icon>
            Delete
          </v-btn>
        </v-list-item>

        <v-list-item>
          <v-btn disabled depressed elevation="0" width="210" class="pa-0 ma-0">
            {{ GetSelectedSize() }}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <VerticalNavMenu v-if="getSelectedLength <= 0 && !isMobile()"></VerticalNavMenu>

    <v-footer app fixed grow color="primary" height="110" style="color: white" class="flex-column" v-if="isMobile()">
      <v-row class="justify-center ma-0 pa-0" style="color: white" v-if="getSelectedLength > 0">
        <v-icon @click.stop.prevent @click="clearSelected()" class="pa-0 ma-3 mt-0" color="white" size="34"
          dark>mdi-close</v-icon>
        <v-icon v-if="getSelectedLength == 1" @click.stop.prevent @click="editMetaDataSelected(selectedCaptures[0])"
          class="pa-0 ma-3 mt-0" color="white" size="34" dark>mdi-pencil-outline</v-icon>
        <v-icon @click.stop.prevent @click="copyURLSelected()" class="pa-0 ma-3 mt-0" color="white" size="34"
          dark>mdi-content-copy</v-icon>
        <v-icon @click.stop.prevent @click="deleteSelectedButton()" class="pa-0 ma-3 mt-0" color="white" size="34"
          dark>mdi-trash-can-outline</v-icon>
      </v-row>

      <v-row class="justify-center ma-0 pa-0" style="color: white" v-else-if="getSelectedLength <= 0">
        <v-icon @click.stop.prevent @click="$router.push('/dashboard/account')" class="pa-0 ma-2 mt-0" color="white"
          size="34" dark>mdi-account-cog-outline</v-icon>
        <v-icon @click.stop.prevent @click="$router.push('/dashboard/captures')" class="pa-0 ma-2 mt-0" color="white"
          size="34" dark>mdi-file-image-outline</v-icon>
        <v-icon @click.stop.prevent @click="" class="pa-0 ma-2 mt-0" color="white" size="34"
          dark>mdi-file-multiple</v-icon>
        <v-icon @click.stop.prevent @click="$router.push('/dashboard/history')" class="pa-0 ma-2 mt-0" color="white"
          size="34" dark>mdi-history</v-icon>

        <v-row class="justify-center ma-0 mt-2 pa-0" style="width: 100%">
          <v-progress-linear class="ma-0 pa-0" color="green" height="10" :value="sizeValue"></v-progress-linear>
          <small class="ma-0 pa-0">{{ footerData }} </small></v-row>
        <v-row class="justify-center ma-0 mt-0 pa-0"><small v-if="!this.$store.state.user.premium">Increase your
            storage by going Pro</small><small v-if="this.$store.state.user.premium"></small></v-row>
      </v-row>
    </v-footer>

  </div>
</template>

<script>
import config from "../../config";
import NavMenuItem from "./NavMenuItem.vue";
import "vue-toasted/dist/vue-toasted.min.css";
import VerticalNavMenu from "./VerticalNavMenu.vue";

export default {
  name: "Images",
  components: {
    VerticalNavMenu,
    NavMenuItem,
  },
  data: function () {
    return {
      captureTitle: "",
      captureDesc: "",
      capturePrivate: true,
      deleteDialog: false,
      deleteSelectedDialog: false,
      editMetaData: false,
      hover: false,
      select: null,
      codebinSelect: null,
      loading: true,
      captures: {},
      rawCaptures: {},
      searchCaptures: {},
      captureToDelete: {},
      capturesDeleting: [],
      selectedCaptures: [],
      captureMetaData: [
        {
          name: "Private metadata",
          icon: "mdi-eye-off-outline",
        },
        {
          name: "Public metadata",
          icon: "mdi-eye-outline",
        },
      ],
      codebinLang: [
        {
          name: "aspnet",
        },
        {
          name: "csharp",
        },
        {
          name: "clike",
        },
        {
          name: "c",
        },
        {
          name: "java",
        },
        {
          name: "js",
        },
        {
          name: "txt",
        },
        {
          name: "html",
        },
        {
          name: "css",
        },
        {
          name: "php",
        },
        {
          name: "r",
        },
        {
          name: "py",
        },
        {
          name: "fsharp",
        },
        {
          name: "rust",
        },
      ],
    };
  },
  watch: {
    getSearch() {
      if (this.rawCaptures != {}) {
        this.searchCaptures = this.rawCaptures.filter((i) =>
          i.name.toLowerCase().includes(this.$store.state.search.toLowerCase())
        );

        this.captures = {};
        this.captures = this.searchCaptures.reduce((prev, curr) => {
          const group =
            prev[
            `${new Date(curr.createdAt).getMonth() + 1}_${new Date(
              curr.createdAt
            ).getFullYear()}`
            ] || [];
          group.push(curr);
          prev[
            `${new Date(curr.createdAt).getMonth() + 1}_${new Date(
              curr.createdAt
            ).getFullYear()}`
          ] = group;
          return prev;
        }, {});
      }
      //console.log(this.$store.state.search);
    },
  },
  computed: {
    sizeValue() {
      if (this.$store.state.user.premium) {
        return parseInt((this.$store.state.user.size / 107374182400) * 100);
      } else {
        return parseInt((this.$store.state.user.size / 104857600) * 100);
      }
    },
    getSearch() {
      return this.$store.state.search;
    },
    getSelectedLength() {
      return this.selectedCaptures.length;
    },
    getCaptures() {
      return this.captures;
    },
    getCapturesLength() {
      if (typeof this.$store.state.user.captureSize !== 'undefined') {
        return this.$store.state.user.captureSize.toString();
      }

      return this.$store.state.captures.length.toString();
    },
    footerData() {
      var bytes = parseInt(this.$store.state.user.size);

      if (bytes == 0) {
        if (this.$store.state.user.premium) {
          return "0% of storage used";
        } else {
          return "0% of storage used";
        }
      }
      if (this.$store.state.user.premium) {
        return `${(bytes / 107374182400 * 100).toFixed(2)}% of storage used`;
      } else {
        return `${(bytes / 104857600 * 100).toFixed(2)}% of storage used`;
      }
    },
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
        return false;
      }

      return true;
    },
    isSelected(capture) {
      if (capture.selected) {
        return 1;
      }

      return 0;
    },
    selectCapturesByMonth(key) {
      for (let index = 0; index < this.getCaptures[key].length; index++) {
        if (!this.selectedCaptures.includes(this.getCaptures[key][index])) {
          this.getCaptures[key][index].selected = true;
          this.selectCapture(this.getCaptures[key][index]);
        }
      }
    },
    getCaptureSrc(capture) {
      if (capture.type == "mp4") {
        capture.url = capture.url.replace(".mp4", ".gif");
      }

      if (capture.type == "txt") {
        return "https://c.onyshare.com/file/onyshare-eu/default-code.png"
      }

      return capture.url;
    },
    GetSelectedSize() {
      var size = 0;
      for (let i = 0; i < this.selectedCaptures.length; i++) {
        size = +size + +this.selectedCaptures[i].size;
      }

      var bytes = size;

      //const thresh = si ? 1000 : 1024;
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + " B";
      }

      const units = 1024
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
      let u = -1;
      const r = 10 ** 1;

      do {
        bytes /= thresh;
        ++u;
      } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
      );

      return `${this.selectedCaptures.length} selected (${bytes.toFixed(2) + " " + units[u]})`;
    },
    SendMeta() {
      if (this.selectedCaptures.length != 1) {
        this.$toasted.show("Failed save metadata", {
          theme: "toasted-primary",
          type: "error",
          position: "top-center",
          duration: 2000,
          iconPack: "mdi",
          icon: "file-alert-outline",
        });
        return;
      }
      var data = [];
      data.push(this.captureTitle);
      data.push(this.captureDesc);
      data.push(this.capturePrivate);

      if (this.selectedCaptures[0].lang) {
        data.push(this.codebinSelect);
      }

      this.$fetch(`${config.URL}/users/m/` + this.selectedCaptures[0].file, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;
            this.$toasted.show("Failed save metadata", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "file-alert-outline",
            });
          } else {
            res.json().then((data) => {
              this.$toasted.show("Successfully saved metadata", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "file-account-outline",
              });
              this.editMetaData = false;
              this.selectedCaptures[0].name = this.captureTitle;
              this.selectedCaptures[0].description = this.captureDesc;
              this.selectedCaptures[0].private = this.capturePrivate;
              this.selectedCaptures[0].lang = this.codebinSelect;
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed save metadata", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "file-alert-outline",
          });
        });
      return;
    },
    GetMetaLabel(capture) {
      if (capture.private) {
        return "Private metadata";
      } else {
        return "Public metadata";
      }
    },
    GetCodeBinLang(capture) {
      return capture.lang;
    },
    GetMetaIcon(item, capture) {
      if (item == null) {
        if (capture.private) {
          this.select = "Private metadata";
          this.capturePrivate = true;
          return "mdi-eye-off-outline";
        } else {
          this.select = "Public metadata";
          this.capturePrivate = false;
          return "mdi-eye-outline";
        }
      }
      if (item == "Private metadata") {
        this.select = "Private metadata";
        this.capturePrivate = true;
        return "mdi-eye-off-outline";
      }
      if (item == "Public metadata") {
        this.select = "Public metadata";
        this.capturePrivate = false;
        return "mdi-eye-outline";
      }
      return item.id;
    },
    editMetaDataSelected(capture) {
      this.editMetaData = true;
      this.captureTitle = capture.name;
      this.captureDesc = capture.description;
      this.capturePrivate = capture.private;
      this.select = null;

      if (capture.lang) {
        this.codebinSelect = capture.lang;
      }
    },

    copyURLSelected() {
      var copyText = "";
      for (let i = 0; i < this.selectedCaptures.length; i++) {
        if(i == 0){
          copyText += this.getCaptureURL(this.selectedCaptures[i].file);
        }else{
          copyText += "\r\n" + this.getCaptureURL(this.selectedCaptures[i].file);
        }

      }

      navigator.clipboard.writeText(copyText);

      this.$toasted.show(
        this.selectedCaptures.length > 1
          ? "Copied URLs to Clipboard"
          : "Copied URL to Clipboard",
        {
          theme: "toasted-primary",
          type: "info",
          position: "top-center",
          duration: 2000,
          iconPack: "mdi",
          icon: "content-copy",
        }
      );
    },
    copyURL(capture) {
      navigator.clipboard.writeText(this.getCaptureURL(capture.file));

      this.$toasted.show("Copied URL to Clipboard", {
        theme: "toasted-primary",
        type: "info",
        position: "top-center",
        duration: 2000,
        iconPack: "mdi",
        icon: "content-copy",
      });
    },
    clearSelected() {
      for (let i = 0; i < this.selectedCaptures.length; i++) {
        this.selectedCaptures[i].selected = false;
      }
      this.selectedCaptures = [];
    },
    selectCapture(capture) {
      for (let i = 0; i < this.selectedCaptures.length; i++) {
        if (this.selectedCaptures[i].file == capture.file) {
          this.selectedCaptures[i].selected = false;
          this.selectedCaptures.splice(i, 1);
          return;
        }
      }
      this.selectedCaptures.push(capture);
    },
    deleteSelectedButton() {
      this.deleteSelectedDialog = true;
    },
    deleteSelected() {
      var data = [];
      for (let i = 0; i < this.selectedCaptures.length; i++) {
        data.push(this.selectedCaptures[i].file);
      }

      this.$fetch(`${config.URL}/users/ds`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;

            this.$toasted.show("Failed to delete", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "trash-can-outline",
            });
            this.capturesDeleting = [];
          } else {
            res.json().then((data) => {
              this.rawCaptures = data;
              this.$store.state.captures = data;
              this.$store.state.user.size = 0;
              for (
                let index = 0;
                index < this.$store.state.captures.length;
                index++
              ) {
                this.$store.state.user.size += Number(
                  this.$store.state.captures[index].size
                );
              }
              this.captures = {};
              this.captures = data.reduce((prev, curr) => {
                const group =
                  prev[
                  `${new Date(curr.createdAt).getMonth() + 1}_${new Date(
                    curr.createdAt
                  ).getFullYear()}`
                  ] || [];
                group.push(curr);
                prev[
                  `${new Date(curr.createdAt).getMonth() + 1}_${new Date(
                    curr.createdAt
                  ).getFullYear()}`
                ] = group;
                return prev;
              }, {});
              this.loading = false;
              this.clearSelected();
              this.$toasted.show("Successfully deleted", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "trash-can-outline",
              });
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to delete", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "trash-can-outline",
          });
        });
      return;
    },
    deleteCaptureButton(capture) {
      this.captureToDelete = capture;
      this.deleteDialog = true;
    },
    deleteCapture(capture) {
      this.captureToDelete = {};

      if (this.capturesDeleting.includes(capture.file)) {
        return;
      }

      this.capturesDeleting.push(capture.file);

      this.$fetch(`${config.URL}/users/d/` + capture.file)
        .then((res) => {
          if (res.status != 200) {
            this.loading = false;

            this.$toasted.show("Failed to delete", {
              theme: "toasted-primary",
              type: "error",
              position: "top-center",
              duration: 2000,
              iconPack: "mdi",
              icon: "trash-can-outline",
            });
            this.capturesDeleting = [];
          } else {
            res.json().then((data) => {
              this.rawCaptures = data;
              this.$store.state.captures = data;

              this.$store.state.user.size = 0;

              for (
                let index = 0;
                index < this.$store.state.captures.length;
                index++
              ) {
                this.$store.state.user.size += Number(
                  this.$store.state.captures[index].size
                );
              }
              this.captures = {};
              this.captures = data.reduce((prev, curr) => {
                const group =
                  prev[
                  `${new Date(curr.createdAt).getMonth() + 1}_${new Date(
                    curr.createdAt
                  ).getFullYear()}`
                  ] || [];
                group.push(curr);
                prev[
                  `${new Date(curr.createdAt).getMonth() + 1}_${new Date(
                    curr.createdAt
                  ).getFullYear()}`
                ] = group;
                return prev;
              }, {});
              this.loading = false;
              this.clearSelected();
              this.$toasted.show("Successfully deleted", {
                theme: "toasted-primary",
                type: "info",
                position: "top-center",
                duration: 2000,
                iconPack: "mdi",
                icon: "trash-can-outline",
              });
            });
          }
        })
        .catch((err) => {
          this.$toasted.show("Failed to delete", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "trash-can-outline",
          });
        });
    },
    getCaptureURL(captureID) {
      //window.open(config.URL + "/c/" + captureID, '_blank');
      return config.URL + "/c/" + captureID;
      //return "http://localhost:8080" + "/c/" + captureID;
    },
    getListText(text) {
      if (text.length > 120) {
        return text.substring(0, 120) + "...";
      }
      return text;
    },
    getSmallerText(text) {
      if (text.length > 17) {
        return text.substring(0, 17) + "...";
      }
      return text;
    },
    getDateString(month) {
      switch (month) {
        case "1":
          return "January";
        case "2":
          return "February";
        case "3":
          return "March";
        case "4":
          return "April";
        case "5":
          return "May";
        case "6":
          return "June";
        case "7":
          return "July";
        case "8":
          return "August";
        case "9":
          return "September";
        case "10":
          return "October";
        case "11":
          return "November";
        case "12":
          return "December";
        default:
          break;
      }
    },
  },
  created: function () {
    this.$fetch(`${config.URL}/users/captures`)
      .then((res) => {
        if (res.status != 200) {
          this.loading = false;
          this.$toasted.show("Failed to load images", {
            theme: "toasted-primary",
            type: "error",
            position: "top-center",
            duration: 2000,
            iconPack: "mdi",
            icon: "image-remove",
          });
        } else {
          res.json().then((data) => {
            this.rawCaptures = data;
            this.$store.state.captures = data;
            this.captures = data.reduce((prev, curr) => {
              const group =
                prev[
                `${new Date(curr.createdAt).getMonth() + 1}_${new Date(
                  curr.createdAt
                ).getFullYear()}`
                ] || [];
              group.push(curr);
              prev[
                `${new Date(curr.createdAt).getMonth() + 1}_${new Date(
                  curr.createdAt
                ).getFullYear()}`
              ] = group;
              return prev;
            }, {});
            this.$store.state.user.size = 0;

            for (
              let index = 0;
              index < this.$store.state.captures.length;
              index++
            ) {
              this.$store.state.user.size += Number(
                this.$store.state.captures[index].size
              );
            }
            this.loading = false;
          });
        }
      })
      .catch((err) => console.log("ERROR", err));
  },
};
</script>

<style>
.monthTitle {
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  background-color: #1976d2;
  width: 100%;
  height: 50px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  line-height: 50px;
}

.video {
  position: absolute;
  z-index: 0;
}

.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: white;
}

.background {
  background-color: #1976d2;
}

.captureTitle {
  font-size: 0.74em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cols10 {
  width: 10%;
  max-width: 10%;
  flex-basis: 10%;
}

.cols7 {
  width: 14.285%;
  max-width: 14.285%;
  flex-basis: 14.285%;
}

.cols4 {
  width: 25%;
  max-width: 25%;
  flex-basis: 25%;
}

.cols3 {
  width: 33%;
  max-width: 33%;
  flex-basis: 33%;
}

.cols2 {
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
}

.cols1 {
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.v-checkbox {
  color: white;
}

.transparents {
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-color: transparent !important;
}
</style>
