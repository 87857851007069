<template>
  <v-navigation-drawer app floating permanent stateless :width="250" class="app-navigation-menu">
    <!-- Navigation Header -->
    <div class="background vertical-nav-header d-flex items-center ps-6 pt-2 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img src="../../../public/white-logo.png" width="30px" alt="logo" contain eager class="app-logo mr-1"></v-img>
        <v-slide-x-transition>
          <h2 class="app-title">Onyshare</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand class="vertical-nav-menu-items mt-4">
      <!-- <nav-menu-item title="Dashboard" to="/dashboard" exact icon="mdi-home-outline"></nav-menu-item> -->
      <nav-menu-item title="Account" to="/dashboard/account" icon="mdi-account-cog-outline"></nav-menu-item>
      <nav-menu-item title="Captures" :subtitle="getCapturesLength" to="/dashboard/captures"
        icon="mdi-file-image-outline"></nav-menu-item>
      <nav-menu-item title="Collections" to="" icon="mdi-file-multiple"></nav-menu-item>
      <nav-menu-item title="History" to="/dashboard/history" icon="mdi-history"></nav-menu-item>
    </v-list>
    
    <v-footer fixed grow color="primary" style="color: white" class="flex-column" v-if="!isMobile()">
      <v-row class="justify-center ma-0 mt-2 pa-0" style="width: 100%">
        <v-progress-linear color="green" height="10" :value="sizeValue"></v-progress-linear>
        <small>{{ footerData }} </small></v-row>
      <v-row class="justify-center ma-0 mt-2 mb-4 pa-0"><small v-if="!this.$store.state.user.premium">Increase your
          storage by going Pro</small><small v-if="this.$store.state.user.premium"></small></v-row>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
import NavMenuItem from "./NavMenuItem.vue";
import NavMenuSectionTitle from "./NavMenuSectionTitle.vue";
export default {
  name: "VerticalNavMenu",
  components: {
    NavMenuItem,
    NavMenuSectionTitle,
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
        return false;
      }

      return true;
    },
    GetMobileSize() {
      if (this.isMobile()) {
        return "60";
      }
      return "250"
    },
  },
  computed: {
    getCapturesLength() {
      if (typeof this.$store.state.user.captureSize !== 'undefined') {
        return this.$store.state.user.captureSize.toString();
      }

      return this.$store.state.captures.length.toString();
    },
    sizeValue() {
      if (this.$store.state.user.premium) {
        return parseInt((this.$store.state.user.size / 107374182400) * 100);
      } else {
        return parseInt((this.$store.state.user.size / 104857600) * 100);
      }
    },
    footerData() {
      var bytes = parseInt(this.$store.state.user.size);

      if (bytes == 0) {
        if (this.$store.state.user.premium) {
          return "0% of storage used";
        } else {
          return "0% of storage used";
        }
      }
      if (this.$store.state.user.premium) {
        return `${(bytes / 107374182400 * 100).toFixed(2)}% of storage used`;
      } else {
        return `${(bytes / 104857600 * 100).toFixed(2)}% of storage used`;
      }
    },
  },
};
</script>

<style scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: white;
}

.background {
  background-color: #1976d2;
}
</style>
